import React from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { signInCallback } from "#redux/slices/userSlice";
import { useDocumentTitle } from "#utils/hooks";

const Home = () => {
  const dispatch = useDispatch();
  const { authError } = useSelector((state) => state.user);
  useDocumentTitle("Home");

  const handleLogin = useGoogleLogin({
    onSuccess: (result) => signInCallback(result, dispatch),
    flow: "auth-code",
  });

  return (
    <>
      <div className="relative lg:h-[1000px] m-auto pt-12 lg:pt-24 bg-pastelBlue z-0 text-center">
        <div className="md:w-[600px] lg:w-[930px] mx-4 lg:mx-auto pb-8">
          <div className="w-full flex flex-col lg:flex-row gap-6 lg:gap-4">
            <div className="lg:w-[550px] shrink-0 text-left">
              <h1
                style={{ lineHeight: 1.3 }}
                className="font-space font-extrabold text-5xl lg:text-6xl text-mainBlue lg:mb-8 mb-4 [text-shadow:-1px_-1px_0_#222,1px_-1px_0_#222,-1px_1px_0_#222,3px_3px_0_#222]"
              >
                Track your books <br />& talk about them
              </h1>
              <h2>Pagebound is a social reading site (think if Goodreads and Reddit had a baby) ✨</h2>
            </div>
            <div className="bg-mainGreen rounded-default border shadow-main p-7 flex flex-col items-center gap-4">
              <b>Welcome! Create a free account to join</b>
              <GoogleLogin
                onSuccess={(result) => signInCallback(result, dispatch)}
                shape="pill"
                text="signup_with"
                width="250"
                ux_mode="popup"
                useOneTap
                auto_select
              />
              {authError && <b className="text-sm text-red">Sorry! That didn't work. Can you try again?</b>}
              <div className="text-xs">
                By continuing, you agree to our Terms of Use and acknowledge that you understand our Privacy Policy.
              </div>
              <div>
                Already have an account?{" "}
                <b className="text-fuschia cursor-pointer" onClick={handleLogin}>
                  Log in
                </b>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:grid md:grid-cols-2 gap-6 md:gap-8 mt-16 md:mt-[78px]">
            <div className="flex-1 p-6 md:px-8 md:py-12 bg-mainBlue border rounded-default">
              <h1 className="mb-4 text-pastelBlue">Every book has a forum</h1>
              <div className="md:text-xl text-white">
                No more bouncing between Goodreads and Reddit/Discord; discuss and keep track of your books in one
                place. 💬
              </div>
            </div>
            <div className="flex-1 p-6 md:px-8 md:py-12 bg-tbr border rounded-default">
              <h1 className="mb-4 text-pastelBlue">Quests gamify reading</h1>
              <div className="md:text-xl text-white">
                Join themed reading challenges and earn badges as you read. Find like-minded readers on the same Quests
                as you. 🏆
              </div>
            </div>
            <div className="flex-1 p-6 md:px-8 md:py-12 bg-red border rounded-default">
              <h1 className="mb-4 text-pastelBlue">Read with others, but read what you want</h1>
              <div className="md:text-xl">
                When you're reading a book, sort forum posts by % read to see what others thought and reacted to.👀
              </div>
            </div>
            <div className="flex-1 p-6 md:px-8 md:py-12 bg-orange border rounded-default">
              <h1 className="mb-4 text-pastelBlue">Tools for modern book tracking</h1>
              <div className="md:text-xl">
                Rate and review the way you always wanted with half-stars, emojis, and sub-ratings. ⭐️ Mark a book as
                paused or DNF.
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage: "url(/assets/unauthHome.png)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className="w-full h-[764px] md:h-[354px] absolute bottom-0 -z-10"
        ></div>
      </div>
      <div className="bg-pastelGreen text-fuschia border border-offBlack py-20 text-center">
        <div className="lg:w-[960px] mx-4 lg:mx-auto">
          <h1 className="font-extrabold">Who is behind Pagebound?</h1>
          <div className="lg:text-xl mt-4">
            Built by <a href="/users/jenniferPagebound">Jennifer Dobak</a> and{" "}
            <a href="/users/lucyPagebound">Lucy Zhao</a>.
            <br />
            Find out more on our{" "}
            <a className="underline" target="_blank" rel="noreferrer" href="https://support.pagebound.co/about-us">
              about us
            </a>{" "}
            page.
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
